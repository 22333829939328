.bl-buchungstool {
    iframe {
        border:0;
        display: block;
        width: 100%;
        aspect-ratio: 16 / 9;

        @media (orientation: portrait) {
            aspect-ratio: 9 / 16;
        }
    }
}
