$black:#000;
$white:#fff;
$gold:#B27A68;
$textcolor:#000;
$footertext:#fff;
$bluetext:#386471;

$bluebg: #386471;
$goldbg: #B27A68;

/*
@mixin borderradius1 {
    //images
    border-radius: 2rem;
}
@mixin borderradius2 {
    //button
    border-radius: 0.5rem;
}
*/
$buttonbg:$goldbg;
$buttonbg-hover:$goldbg;

body {
    --gap1:2rem;
    --gap2:4rem;
}