.bl-herohome {
    background:linear-gradient(180deg, $bluebg, $bluebg 90%, transparent 90%, transparent);
    
    .ctrimage {
        img {
            @include w100bl;
            @include breakpoint(large) {
                width:66%;
                margin-left: auto;
            }
            @media (max-width:500px) {
                aspect-ratio: 1 / 1;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
    
    .wconstrain {
        position: relative;
    }
    .ctrtext {
        position: absolute;
        left:0;
        max-width:100%;
        @include rem-calc-sl(top, 80, 0.5);

        @include breakpoint(medium down) {
            left:1em;
            right:1em;
        }
    }
}

.bl-herohome .ctrtext {
    @include typo-imgoverlay;
    color:$white;
    text-shadow: 0px 2px 4px #2a6472;
}