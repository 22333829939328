.bl-textwithimage {
    .colimage img {
        @include w100bl;
    }
    @include breakpoint(large) {
        .cols {
            display:grid;
            grid-template-columns: 1fr 1fr;
            gap:var(--gap1);
        }
        &.imgpos-l .coltext {
            order:2;
        }
    }
    @include breakpoint(medium down) {
        .colimage {
            margin-top: 2em;
        }
    }
}

