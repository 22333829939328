footer {
  .flexh {
    gap:var(--gap2);
  }
  @include rem-calc-sl(padding-top, 70, 0.5);
  @include rem-calc-sl(padding-bottom, 70, 0.5);
  .logo {
      @include w100bl;
      @include rem-calc-sl(width, 210, 0.5);
  }
  .socialicons {
    display:flex;
    gap:0.75em;
    margin-top:0.4em;
  }
  .icon {
    height: 1.25em;
  }

  @include breakpoint(medium down) {
    .addrpart2, .ctrcopy {
      margin-top: 1em;
    }
    .colr {
      margin-top: 2em;
    }
  }
}

footer {
  @include typo-footer;
  color:$footertext;
  background-color: $goldbg;

  h2 {
    margin-bottom: 0.4em;
    font-size: inherit;
  }
}
