#desktopmenu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    z-index: 50;

    color:white;

    display:flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding:10vw;
    overflow: auto;

    background-color:$gold;

    .menuitems {
        ul {
            @include reset-list;
        }
        li {
            text-align: center;
        }
    }

    /*
    .ctrlogo {
        position: absolute;
        @include rem-calc-sl(left, 45, 0.5);
        @include rem-calc-sl(top, 41, 0.5);
        @include rem-calc-sl(width, 58, 1);

        img {
            @include w100bl;
        }
    }
    */
}

#desktopmenu {
    .menuitems {
        @include font-size-pt(40*0.75, 40);
        font-weight: 400;
        letter-spacing: 0em;
        line-height: math.div(70, 40);
    }
}

#desktopmenu {
    transform: translateY(-100%);
    transition:transform 0.3s;

    &.visible {
        transform: none;
    }
}