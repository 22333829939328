.bl-quote {
    @include breakpoint(xlarge) {
        max-width: rem-calc(880);
        margin-left: rem-calc(250);
    }

    .quote {
        @include typo-quote;
        color:$bluetext;
    }
    .author {
        margin-top: 1em;
    }
}
