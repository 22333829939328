header {
  @include rem-calc-sl(padding-top, 50, 0.5);
  @include rem-calc-sl(padding-bottom, 50, 0.5);

  background-color: $bluebg;
  color:$gold;

  .ctrlogo {
    display: block;
    @include rem-calc-sl(width, 228, 0.5);
    img {
        @include w100bl;
    }
  }

  .ctrlr {
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
    @include breakpoint(medium down) {
      align-items: center;
    }
  }

  @include breakpoint(large) {
    #menubutton {
      display:none;
    }
  }
  @include breakpoint(medium down) {
    .ctrmenu {
      //margin-top:1em;
      display:none;
    }
  }
}

header .ctrmenu {
  ul {
    @include reset-list;
    display:flex;
    gap:1em;

    li.active, li:hover {
      color:$white;
    }
  }
}

header .ctrmenu {
  @include typo-menu;
}

header {
  position:sticky;
  top:0;
  z-index: 50;
}
header {
  .ctrlogo {
    transition:width 0.2s;
  }
}
header.opaque {
  @include rem-calc-sl(padding-top, 25, 0.5);
  @include rem-calc-sl(padding-bottom, 25, 0.5);
  .ctrlogo {
    @include rem-calc-sl(width, 150, 0.5);
  }
}