//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

@mixin typo-base {
  //Dynamic Font Size (mobile, desktop)
  @include font-size-pt(24*0.75, 24);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:math.div(32, 24);
  color:$textcolor;
}

body {
  font-family:'Roboto', sans-serif;
  @include typo-base;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color:inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

@mixin typo-h1 {
  font-family:'Yeseva One', sans-serif;
  @include font-size-pt(48*0.75, 48);
  font-weight: 400;
  letter-spacing: 0em;
  line-height: math.div(64, 48);
  color:$bluetext;
}
@mixin typo-h2 {
  @include font-size-pt(32*0.75, 32);
  font-weight: 700;
  letter-spacing: 0em;
  line-height: math.div(38, 32);
}
@mixin typo-h3 {
  @include typo-h2;
}
@mixin typo-h4 {
  @include typo-h2;
}

@mixin typo-lead {
  @include font-size-pt(28*0.75, 28);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:math.div(37, 28);
}
@mixin typo-imgoverlay {
  font-family: 'Yeseva One', serif;
  @include font-size-pt(60*0.5, 60);
  font-weight: 400;
  letter-spacing: 0em;
  line-height: math.div(80, 60);
}
@mixin typo-quote {
  @include typo-imgoverlay;
}
@mixin typo-footer {
  @include font-size-pt(18*0.75, 18);
  font-weight: 400;
  letter-spacing: 0em;
  line-height:math.div(22, 18);
}
@mixin typo-menu {

}

strong {
  font-weight: 700;
}

address {
  font:unset; //remove italic browser style
}

p {
  margin:0 0 1em 0;
}
p:last-child {
  margin-bottom:0;
}
p a {
  //text-decoration: underline;
  //text-decoration-thickness: 1px;
  color:$gold;
}

h1, h2 {
  margin:0 0 0.75em 0;
}
h3 {
  margin:0 0 0.5em 0;
}
h4 {
  margin:0 0 0.25em 0;
}
h1 {
  @include typo-h1;
}
h2 {
  @include typo-h2;
}
h3 {
  @include typo-h3;
}
h4 {
  @include typo-h4;
}