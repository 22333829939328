//ul:not(.menu):not(.form):not(.tabs) {
.bl-richtext ul,
.bl-textwithimage .coltext ul {
  margin-left: 1em;
  padding-left: 0;
  list-style-type: none;
  li {
    &:before {
      content: "– ";
      margin-left: -1em;
      margin-right: 0;
      width: 1em;
      display:inline-block;
    }
  }
}
